.about-us {
  background-color: #000; 
  color: #fff; 
  padding: 50px 20px;
}

.about-us .content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left; 
}

.about-us h2,
.about-us h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.about-us h3 {
  font-size: 2rem;
  margin-top: 40px;
}

.about-us p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: justify; 
}

.services {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  gap: 30px;
  margin-top: 40px;
}

.service-card {
  background-color: #1a1a1a; 
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  opacity: 0; 
  transform: translateY(50px); 
  transition: opacity 1s ease, transform 1s ease; 
}

.service-card.revealed {
  opacity: 1; 
  transform: translateY(0); 
}

.service-card h4,
.service-card p {
  opacity: 0; 
  transform: translateY(30px); 
  transition: opacity 1s ease, transform 1s ease; 
}

.service-card.revealed h4,
.service-card.revealed p {
  opacity: 1; 
  transform: translateY(0); 
}

.service-card:hover {
  transform: translateY(-10px); 
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4); 
  background-color: #333; 
}

.service-icon {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 15px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.service-card:hover .service-icon {
  transform: scale(1.2); 
  color: #fff; 
}

.service-card h4 {
  font-size: 1.5rem;
  margin: 15px 0;
}

.service-card p {
  font-size: 1rem;
  color: #ccc;
}

.service-card:hover p {
  color: #fff; 
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .about-us h2 {
      font-size: 2rem;
  }

  .about-us h3 {
      font-size: 1.5rem;
  }

  .about-us p,
  .services .service-card p {
      font-size: 1rem;
  }

  .services {
      grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .services {
      grid-template-columns: 1fr;
  }
}
