.locations-section {
  background-color: #000; 
  color: #fff; 
  padding: 3rem 1rem;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.locations-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.locations-filter {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.filter-button {
  background-color: #333; 
  color: #fff;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.filter-button.active {
  background-color: #fff; 
  color: #000;
  font-weight: bold;
}

.filter-button:hover {
  transform: scale(1.1);
}

.locations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.location-card {
  background-color: #1a1a1a; 
  border: 1px solid #333;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.location-card:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.1);
}

.location-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #333;
}

.location-title {
  font-size: 1.2rem;
  text-align: center;
  padding: 0.5rem;
}

.view-button {
  background-color: #272727;
  color: #fff;
  padding: 0.5rem 1rem;
  /* border: #fff; */
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0rem;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #555;
}

.locations-full-view {
  text-align: center;
}

.locations-full-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.close-button {
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 2rem;
}

.close-button:hover {
  background-color: #555;
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s ease;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.3s ease-out;
}

.modal-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 500px; 
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.modal-prev,
.modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  padding: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s;
}

.modal-prev:hover,
.modal-next:hover {
  background: rgba(0, 0, 0, 0.8);
}

.modal-prev {
  left: 25%;
}

.modal-next {
  right: 25%;
}

.modal-close {
  position: absolute;
  top: -10px;
  right: 22%;
  background: transparent;
  color: #fff;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  transition: transform 0.2s;
}

.modal-close:hover {
  transform: scale(1.2);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
}

/* Responsiveness */
@media (max-width: 768px) {
  .modal-image-container {
    height: 350px;
  }

  .modal-prev,
  .modal-next {
    padding: 0.8rem;
    font-size: 1.3rem;
  }

  .modal-close {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .modal-image-container {
    height: 250px;
  }

  .modal-prev,
  .modal-next {
    padding: 0.5rem;
    font-size: 1.2rem;
  }

  .modal-close {
    font-size: 1.2rem;
  }
}
