.contact-form {
    background-color: #f9f9f9;
    padding: 4rem 2rem;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 850px;
    margin: 0 auto;
  }
  
  .form-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  input, textarea {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
  }
  
  textarea {
    resize: vertical;
  }
  
  .submit-button {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #555;
  }
  

  /* Success and Error Messages */
.success-message {
  color: #28a745; 
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

.error-message {
  color: #dc3545;
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

/* Button Disabled State */
.submit-button:disabled {
  background-color: #ccc; 
  cursor: not-allowed;
}
