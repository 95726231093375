.contact-hero {
    background-color: #000;
    color: #fff;
    padding: 6rem 2rem;
    text-align: center;
    position: relative;
    margin-top: -80px;
  }
  
  .hero-title {
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
  }
  