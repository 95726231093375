.about-us-details-section {
  background-color: #000; 
  color: #fff;
  padding: 3rem 1rem;
  font-family: Arial, sans-serif;
}

.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.about-us-section-title {
  font-size: 2rem; 
  text-align: center;
  margin-bottom: 3rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600; 
}

.about-us-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.about-us-section-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem;
  border: 1px solid #333;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-us-section-item:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.1);
}

.about-us-icon {
  font-size: 3rem; 
  color: #000000; 
  background-color: #fff; 
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.about-us-icon:hover {
  background-color: #fff;
  color: #000;
  transform: rotate(360deg);
}

.about-us-text-content {
  flex: 1;
}

.about-us-text-content .about-us-section-title {
  font-size: 1.5rem; 
  margin-bottom: 0.5rem;
}

.about-us-text-content .about-us-section-description {
  font-size: 1rem; 
  line-height: 1.8;
  text-align: justify;
}
