.key-achievements {
    background-color: #000;
    color: #fff;
    padding: 3rem 1rem;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .section-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 3rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
  }
  
  .achievements-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .achievement-item {
    display: flex;
    flex-direction: column; 
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    border: 1px solid #333;
    border-radius: 10px;
    flex: 1 1 calc(25% - 2rem); 
  }
  
  .achievement-icon {
    font-size: 4rem; 
    color: #fff;
    background-color: #000;
    padding: 1.2rem;
    border-radius: 50%;
  }
  
  .achievement-text {
    flex: 1;
    text-align: center;
  }
  
  .achievement-title {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .achievement-description {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 0.5rem;
  }
  
  .achievement-stat {
    font-size: 1.3rem;
    font-weight: 600;
  }
  