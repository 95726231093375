.project-categories {
    background-color: #000; 
    color: #fff; 
    padding: 3rem 1rem;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .categories-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
  
  .categories-filter {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }
  
  .filter-button {
    background-color: #333; 
    color: #fff;
    border: none;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .filter-button.active {
    background-color: #fff; 
    color: #000;
    font-weight: bold;
  }
  
  .filter-button:hover {
    transform: scale(1.1);
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .project-card {
    background-color: #1a1a1a; 
    border: 1px solid #333;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.1);
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #333;
  }
  
  .project-title {
    font-size: 1.2rem;
    text-align: center;
    padding: 0.5rem;
  }
  