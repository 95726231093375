.hero-section {
  position: relative;
  width: 100%;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; 
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; 
}

.content {
  text-align: center;
  color: white;
  z-index: 1; 
}

.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.projects-button {
  padding: 10px 30px;
  font-size: 1rem;
  background-color: transparent;
  color: white;
  border: 2px solid white; 
  border-radius: 50px; 
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.projects-button:hover {
  background-color: white; 
  color: black; 
}

/* Custom Particle Animation Container */
.particle-animation-container {
  position: absolute; 
  bottom: 20px; 
  width: 100%;
  height: 100%;
  pointer-events: none; 
  z-index: 0; 
}
