.what-we-do {
  background-color: #000; 
  color: #fff;
  padding: 50px 20px;
}

.what-we-do .content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left; 
}

.what-we-do h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.what-we-do .services {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.what-we-do .service {
  flex: 1 1 calc(25% - 30px); 
  min-width: 200px;
  text-align: justify;
  opacity: 0; 
  transform: translateY(50px); 
  transition: opacity 1s ease, transform 1s ease; 
}

.what-we-do .service.revealed {
  opacity: 1; 
  transform: translateY(0); 
}

.what-we-do .service h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.what-we-do .service p {
  font-size: 1rem;
  line-height: 1.6;
}

.what-we-do .icon {
  font-size: 3rem;
  margin-bottom: 10px;
  transition: transform 0.3s ease, color 0.3s ease; 
}

.what-we-do .icon:hover {
  transform: scale(1.2); 
  color: #fff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .what-we-do .services {
    flex-direction: column;
  }
}
