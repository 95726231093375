.our-clients {
    background-color: #000; 
    color: #fff; 
    padding: 50px 20px;
    text-align: center;
  }
  
  .our-clients h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .client-logos {
    overflow: hidden;
    position: relative;
  }
  
  .logos-container {
    display: flex;
    animation: move-left-to-right 50s linear infinite;
  }
  
  .client-logo {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
  }
  
  .client-logo:hover {
    transform: scale(1.2); 
  }
  
  .clogo-img {
    max-width: 70px; 
    height: auto;
  }
  
  /* Animation: Move from left to right and right to left */
  @keyframes move-left-to-right {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @media (max-width: 768px) {
    .our-clients h2 {
      font-size: 2rem;
    }
  
    .client-logo {
      margin: 0 10px;
    }
  
    .clogo-img {
      max-width: 40px; 
    }
  }
  