
/* Fullscreen reset */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden;

}


  
  /* Hero section styling */
  .hero {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    z-index: 10;
    background: #000000; 
    margin-top: -80px;
  }
  
  
  #particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  
  /* Hero content styling */
  .hero-content {
    position: relative;
    z-index: 10;
    /* padding: 20px; */
  }
  
  .hero h1 {
    font-size: 3rem;
    margin: 20px 0;
    line-height: 1.3;
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    padding: 12px 25px;
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    border: 2px solid #fff;
    border-radius: 50px; 
    transition: all 0.3s ease;
    font-size: 20px; 
  }
  
  .cta-button:hover {
    background-color: #fff;
    color: #111;
    border-color: #fff;
  }
  
  /* Logo styling */
  .logo {
    margin-bottom: -50px;
  }
  
  .logo-img {
    width: 250px; 
    height: auto;
  }
  
  /* Responsiveness for mobile */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2rem; 
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .cta-button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  
    .logo-img {
      width: 120px; 
    }
  }
  
  /* Responsiveness for tablets */
  @media (max-width: 1024px) {
    .hero h1 {
      font-size: 2.5rem; 
    }
    .hero p {
      font-size: 1.1rem;
    }
  
    .logo-img {
      width: 250px; 
    }
  }
  



  