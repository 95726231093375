.meet-the-team {
    background-color: #000;
    color: #fff;
    padding: 3rem 1rem;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .section-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 3rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
  }
  
  .team-members {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .team-member {
    background-color: #222;
    padding: 1.5rem;
    border-radius: 10px;
    flex: 1 1 calc(50% - 2rem); 
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .team-member:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.1);
  }
  
  .team-member-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .team-member-info {
    flex: 1;
  }
  
  .team-member-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .team-member-role {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #ccc;
  }
  
  .team-member-social {
    display: flex;
    gap: 1.5rem;
    justify-content: center; 
    margin: 1rem 0;
  }
  
  .social-icon {
    font-size: 1.5rem;
    color: #707070;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #ffffff; 
  }
  
  .team-member-bio {
    font-size: 1rem;
    line-height: 1.6;
    color: #ccc;
  }
  