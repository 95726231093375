/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #333; 
}

::-webkit-scrollbar-thumb {
  background-color: #555; 
  border-radius: 10px; 
  border: 2px solid #333; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888; 
}

/* Ensure the body or relevant containers are scrollable */
body, .why-choose-us {
  overflow-y: auto; 
  scrollbar-width: thin;
  scrollbar-color: #555 #333; 
}

/* Other styles */
.why-choose-us {
  background-color: #000;
  color: #fff;
  padding: 50px 20px;
}

.why-choose-us .content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.why-choose-us h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center; 
}

.faq {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.faq-item {
  background-color: transparent;
  padding: 10px 0;
  cursor: pointer;
  opacity: 0; 
  transform: translateY(30px); 
  transition: opacity 1s ease, transform 1s ease; 
}

.faq-item.revealed {
  opacity: 1; 
  transform: translateY(0); 
}

.faq-item:hover {
  transform: translateX(10px); 
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-question h4 {
  font-size: 1.5rem;
  margin: 0;
  color: #fff;
}

.faq-question svg {
  font-size: 1.5rem;
  color: #fff;
  transition: transform 0.3s ease;
}

.faq-question:hover svg {
  transform: rotate(180deg);
}

.answer {
  font-size: 1.1rem;
  color: #ccc;
  line-height: 1.8;
  padding-left: 20px; 
  margin-top: 10px;
}

.answer p {
  margin: 0;
  padding: 10px 0;
}

/* Responsive styling */
@media (max-width: 768px) {
  .why-choose-us h2 {
      font-size: 2rem;
  }

  .faq-item h4 {
      font-size: 1.3rem;
  }

  .answer {
      font-size: 1rem;
  }
}
