.hire-us-now {
    background-color: #000; 
    color: #fff; 
    padding: 50px 20px;
    text-align: center;
  }
  
  .hire-us-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .hire-us-content p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
  
  .hire-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.1rem;
    text-align: left;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 2px solid #fff;
    border-radius: 4px;
    background-color: #222;
    color: #fff;
  }
  
  .form-group textarea {
    height: 150px;
    resize: vertical;
  }
  
  .cta-button {
    padding: 12px 30px;
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    border: 2px solid #fff;
    border-radius: 50px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
  }
  
  @media (max-width: 768px) {
    .hire-us-content h2 {
      font-size: 2rem;
    }
  
    .hire-us-content p {
      font-size: 1rem;
    }
  
    .cta-button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }
  

  .success-message {
    color: #28a745; 
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .error-message {
    color: #dc3545; 
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .cta-button:disabled {
    background-color: #ccc; 
    cursor: not-allowed;
  }
  
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: #fff;
    color: #000;
    padding: 20px 30px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 80%;
  }
  
  .modal h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .modal button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .modal button:hover {
    background-color: #333;
  }
  
  