.title-subtitle-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 2; 
  }
  
  .page-title {
    font-size: 3rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  
  .page-subtitle {
    font-size: 1.5rem;
    margin-top: 0.5rem;
    line-height: 1.6;
  }
  