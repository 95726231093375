.testimonials {
  background-color: #000; 
  color: #fff; 
  padding: 4rem 1rem;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: #fff; 
  text-transform: uppercase;
  font-weight: 600;
}

.testimonial-cards {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: #222; 
  color: #fff; 
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  width: calc(33.33% - 2rem);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
}

.testimonial-quote {
  position: relative;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.quote-icon {
  position: absolute;
  top: -1rem;
  left: -1rem;
  font-size: 1rem;
  color: #fff; 
}

.quote {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #ddd; 
  text-align: center;
}

.testimonial-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.testimonial-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.testimonial-details {
  text-align: left;
}

.testimonial-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.testimonial-role {
  font-size: 1rem;
  color: #bbb; 
}

/* Responsive Design */
@media (max-width: 1024px) {
  .testimonial-cards {
    justify-content: center;
  }

  .testimonial-card {
    width: calc(50% - 1rem); 
  }

  .section-title {
    font-size: 2rem; 
  }

  .quote {
    font-size: 1.1rem; 
  }
}

@media (max-width: 768px) {
  .testimonial-cards {
    flex-direction: column; 
    align-items: center;
  }

  .testimonial-card {
    width: 90%; 
    margin-bottom: 1.5rem; 
  }

  .section-title {
    font-size: 1.8rem; 
  }

  .quote {
    font-size: 1rem; 
  }
}

@media (max-width: 480px) {
  .testimonial-card {
    width: 95%; 
  }

  .testimonial-photo {
    width: 50px;
    height: 50px;
  }

  .testimonial-name {
    font-size: 1rem; 
  }

  .testimonial-role {
    font-size: 0.9rem; 
  }

  .quote {
    font-size: 0.9rem; 
  }
}
