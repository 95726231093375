.featured-projects {
    background-color: #000; 
    color: #fff; 
    padding: 3rem 1rem;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .featured-projects-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .project-card {
    background-color: #1a1a1a; 
    border: 1px solid #333;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.1);
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-content {
    padding: 1rem;
  }
  
  .project-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .project-description {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .project-highlights {
    font-size: 0.9rem;
    color: #bbb;
    margin-bottom: 1rem;
  }
  
  .view-project-button {
    background-color: #fff;
    color: #000;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration-line: none;
  }
  
  .view-project-button:hover {
    background-color: #000;
    color: #fff;
    border: 1px solid #fff;
  }
  