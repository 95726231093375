/* Glassmorphism effect on navbar */
.navbar-glass {
  background: rgba(0, 0, 0, 0.4); 
  backdrop-filter: blur(12px); 
  padding: 10px 20px; 
  transition: background 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  height: 80px;
  width: 100%;
  margin-bottom: -80px;
}

/* Logo image styling */
.navbar-logo {
  width: 80px; 
  height: auto;
}

/* Navbar Links */
.navbar-nav .nav-link {
  color: #525252; 
  font-size: 1.2rem; 
  margin-left: 15px;
  margin-top: 15px; 
  font-weight: normal; 
  transition: color 0.3s ease; 
}

/* Contact button styling */
.navbar-nav .contact {
  color: #c2c2c2;
  font-size: 1.2rem; 
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 5px 15px;
  text-align: center;
  transition: none; 
  background: transparent;
}

/* Full width background when menu is open */
.navbar-glass.menu-open {
  background: rgba(0, 0, 0, 0.6); 
  backdrop-filter: blur(20px);
}

/* Navbar Links when the menu is open */
.navbar-links.open {
  display: flex;
}

/* Hamburger Button */
.hamburger {
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 24px;
  cursor: pointer;
  z-index: 105; 
}

.hamburger .line {
  width: 30px;
  height: 4px;
  background-color: white;
  border-radius: 2px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Animation for lines when hamburger is clicked */
.hamburger.open .line-1 {
  transform: rotate(45deg) translateY(10px);
}

.hamburger.open .line-2 {
  opacity: 0;
}

.hamburger.open .line-3 {
  transform: rotate(-45deg) translateY(-10px);
}

/* Responsive Navbar Design */
@media (max-width: 768px) {
  .navbar-glass {
    padding: 10px;
  }

  /* Navbar links hidden by default on mobile */
  .navbar-nav {
    display: none;
    text-align: left; 
    margin-top: 80px;
    padding-left: 20px; 
  }

  /* Show navbar links when the menu is open */
  .navbar-nav.open {
    display: flex;
    flex-direction: column; 
  }

  /* Hide the hamburger menu on large screens */
  .hamburger-mobile {
    display: block;
  }

  /* Hide the menu by default on mobile */
  .navbar-links {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 250px;
    height: 100vh;
    z-index: 100; 
    visibility: hidden; 
    opacity: 0; 
    transform: translateX(100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    font-weight: bold;
    padding-right: 70px; 
  }

  /* Display links when hamburger is clicked */
  .navbar-links.open {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  /* Prevent body from adding unwanted space */
  body {
    margin-top: 0; 
  }
}

/* Prevent body or html from adding unwanted space */
body, html {
  margin: 0;
  padding: 0;
}

/* Ensure sections below navbar are not affected */
main, .hero-section, .section {
  margin-top: 0px; 
  padding-top: 0;
}

/* Hide hamburger menu in web view */
@media (min-width: 769px) {
  .hamburger-mobile {
    display: none;
  }
}

/* On Hover - Turn Links Red */
.navbar-nav .nav-link:hover {
  color: rgb(255, 255, 255); 
}

/* On Click (Active state) - Make Link Red */
.navbar-nav .nav-link.active {
  color: rgb(255, 255, 255) !important; 
  font-weight: bold; 
 
}

/* Contact button styling */
.navbar-nav .contact {
  color: #fff;
  font-size: 1rem; 
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 5px 15px;
  text-align: center;
  transition: none;
  background: transparent;
}
