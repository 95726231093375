.mission-vision-values-section {
    background-color: #000; 
    color: #fff;
    padding: 3rem 1rem;
    font-family: Arial, sans-serif;
  }
  
  .mission-vision-values-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .mission-vision-values-title {
    font-size: 2rem; 
    text-align: center;
    margin-bottom: 3rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
  }
  
  .mission-vision-values-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .mission-vision-values-item {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    max-width: 350px;
  }
  
  .mission-vision-values-item:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.1);
  }
  
  .icon-container {
    font-size: 3rem; 
    color: #000000; 
    background-color: #fff;
    padding: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .icon-container:hover {
    background-color: #fff;
    color: #000;
    transform: rotate(360deg);
  }
  
  .text-content {
    text-align: center; 
  }
  
  .text-content .section-title {
    font-size: 1.5rem; 
    margin-bottom: 0.5rem;
  }
  
  .text-content .section-description {
    font-size: 1rem; 
    line-height: 1.8;
    text-align: justify;
  }
  