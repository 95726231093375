.location-page {
  background-color: #000; 
  color: #fff;
  padding: 0;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden; 
}

.hero-section {
  position: relative;
  width: 100%;
  height: 100vh; 
  display: flex;
  justify-content: center; 
  align-items: center;
  z-index: 1; 
}
